import React from "react";
import styled from "styled-components";

const SectionWrapper = ({ children, collapsed = false, ...props }) => {
    return (
        <Wrapper
            className={`${
                collapsed ? "collapsed" : ""
            } context-item d-flex bg-white text-primary p-2 shadow-sm align-items-center me-2`}
            {...props}
        >
            <div>{children}</div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    span {
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        transition: max-width 0.3s ease-in-out;
        transition-delay: 0.1s;
        max-width: 130px;
        &:after {
            vertical-align: middle;
            content: "";
            height: 1em;
            width: 1em;
            margin-left: 0.5rem;
            background-color: var(--bs-primary);
            -webkit-mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><circle cx='50%' cy='50%' r='6' /></svg>");
            mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><circle cx='50%' cy='50%' r='6' /></svg>");
        }
    }
    &.collapsed {
        cursor: pointer;
        display: flex !important;
        span {
            transition: max-width 0.2s ease-in-out;
            margin: 0px !important;
            max-width: 0;
        }
    }
`;

export default SectionWrapper;
