import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { inViewport } from "../../support/helpers";
import { usePageAction } from "../../hooks/usePageAction";
import styled from "styled-components";

/**
 * Feature component wraps a feature element with title and show logic
 * @param {React Component} children - feature component
 * @param {string} title - Title of feature
 * @param {boolean} show - Should show feature
 */
const Feature = ({
    children,
    childWrapperClassName = "text-break px-3",
    title,
    show,
    monitor = false,
    isThirdParty = false,
    ...props
}) => {
    const mode = useSelector((state) => state.root.mode);
    const [hasExecuted, setHasExecuted] = useState(false);
    const { execute: setPageAction } = usePageAction();
    const featureRef = useRef(null);

    const featureView = useCallback(() => {
        if (inViewport(featureRef.current)) {
            setPageAction("featureView", { type: title });
            setHasExecuted(true);
        }
    }, [featureRef, inViewport, setPageAction]);

    useEffect(() => {
        if (monitor) {
            if (!hasExecuted) {
                document.addEventListener("scroll", featureView);
            } else {
                document.removeEventListener("scroll", featureView);
            }
        }
        return () => document.removeEventListener("scroll", featureView);
    }, [hasExecuted, monitor]);

    if (mode !== "edit" && !show) return null;
    return (
        <FeatureWrapper
            className={`mb-4 ${isThirdParty ? "is-third-party" : null}`}
            ref={featureRef}
            {...props}
        >
            <h2 className="w-100 fs-3 border-bottom mb-2 .h4">{title}</h2>
            <div className={childWrapperClassName}>{children}</div>
        </FeatureWrapper>
    );
};

const FeatureWrapper = styled.div`
    &.is-third-party {
        h2 {
            display: none;
            &:has(+ div > .check-content > *) {
                display: block;
            }
        }
    }
`;

export default Feature;
