import React from "react";
import DiItems from "../DisplayItems/DiItems";
import styled from "styled-components";

const Overview = ({ data }) => {
    const formatOverview = (overView) => {
        const itemIds = [
            "shoreline_miles",
            "lake_depth_avg",
            "lake_depth_max",
            "annual_precipitation",
            "high_temp_avg",
            "low_temp_avg",
        ];
        const headerContent = [];
        const content = overView.filter((item) => {
            if (itemIds.toString().includes(item.id)) {
                let value = item.value;
                // Adding unit of measurement for temp here until we have a better solution
                if (item.id.includes("temp")) {
                    value = `${item.value}°F`;
                }
                headerContent.push({ ...item, value, iconName: item.id });
                return false;
            } else {
                return true;
            }
        });
        return {
            content,
            headerContent,
        };
    };
    const overviewData = formatOverview(data?.overview || data);
    return (
        <div>
            <ul className="list-inline features-overview d-md-flex flex-wrap justify-content-between">
                <DiItems
                    Wrapper="li"
                    items={overviewData.headerContent}
                    wrapperClsses="d-flex align-items-baseline mb-3"
                    valueClasses="h4 me-2 align-bottom"
                    labelSuffixClasses="fw-bold align-bottom px-1"
                    iconClasses="me-2 align-bottom text-primary"
                />
            </ul>
            <OverviewItems className="list-inline">
                <DiItems
                    Wrapper="li"
                    wrapperClsses="mb-2"
                    items={overviewData.content}
                    labelClasses="w-50 d-inline-block fw-bold  align-top"
                    valueClasses="w-50 d-inline-block text-muted align-top"
                    verticalLabelClasses="d-block w-auto"
                    verticalValueClasses="w-auto ms-4"
                    valueIsHtml={true}
                />
            </OverviewItems>
        </div>
    );
};

const OverviewItems = styled.ul`
    @media (min-width: 768px) {
        column-count: 2;
    }
`;

export default Overview;
