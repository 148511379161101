import React, { useEffect } from "react";
import useTrackEvent from "../../hooks/useTrackEvent";

/**
 * Cordless Media Additional Services widget
 * https://ads.cordlessmedia.com/documentation/index.html#additionalServices.md
 * This is just the html tag portion of the implementation. The JS is added in Google Tag Manager.
 */
const AdditionalServices = ({ zipCode, address, aptNumber }) => {
    const { trackGTM } = useTrackEvent();

    useEffect(() => {
        trackGTM({
            event: "additionalServices",
            type: "cordlessMedia",
            location: "Listing Page",
            zipCode,
            address,
            aptNumber,
        });
    }, []);

    return <div id="cmasw" className="check-content"></div>;
};

export default AdditionalServices;
